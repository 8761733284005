import { useContext } from "react";
import { LangContext } from "../../../context/Lang.context";

const texts = {
  en: {
    h1: "On average, it takes our services",
    h2: "to",
    h3: "weeks to process your application.",
    l2: "Additional requests may be sent to you via email. Once your application is approve",
    l3: "a repsresentative will contact you to provide you with further information",
    applyFor: "Apply For Affiliation",
    identity: "Identity",
    name: "Full name",
    phone: "Phone",
    city: "City",
    mail: "E-mail",
    adress: "Adress",
    codePostal: "Postal Code",
    exandex1: "Expertise",
    exandex2: "And",
    exandex3: "Experience",
    sokenLang: "Spoken Languages ( with priority )",
    dip1: "Diploma 1",
    dip2: "Diploma 2",
    exp1: "Experience 1",
    exp2: "Experience 2",
    attach: "Attach Files",
    attestation: "Attestation insee or Kbis",
    identity: "Identity document",
    criminal: "Criminal record extract",
    prof: "Professional Photo",
    chooseFile: "Choose Files",
    noFile: "No Chosen File",
    addMoreFile: "Add More Files",
    submit: "Submit",
    full_name: "Name is empty",
    email: "Email is empty",
    mailFormatError: "Invalid email format",
    phoneError: "Phone Number is empty",
    cityError: "City is empty",
    adressError: "Adress is empty",
    postal_code: "Postal code is empty",
    spoken_lang: "Spoken languages is empty",
    diploma1: "Diploma 1  is empty",
    exp1Error: "Experience 1  is empty",
    diploma2: "diploma 2  is empty",
    exp2Error: "Experience 2  is empty",
    inseeKbis: "Missing Attestation insee or Kbis file",
    identityDoc: "Missing Identity document file",
    criminalRecord: "Missing Criminal record extract file",
    proPhoto: "Missing Professional Photo file",
    error: "Error while sending demand.",
    sending: "Sending in progress...",
    success: "Your demand has been successfully sent",
  },

  fr: {
    h1: "Veuillez compter en moyenne",
    h2: "à",
    h3: "semaines pour que votre candidature soit traitée par notre service.",
    l2: "Veuillez nous adresser par mail pour toute autre demande ou tout renseignement complémentaires.",
    l3: "",
    applyFor: "Soumettre Votre Candidature",
    identity: "Identité",
    name: "Nom et prénom",
    phone: "Mobile",
    city: "Ville",
    mail: "E-mail",
    adress: "Adresse",
    codePostal: "Code postal",
    exandex1: "Formation",
    exandex2: "Et",
    exandex3: "Expérience",
    sokenLang: "Langues parlées",
    dip1: "Niveau 1",
    dip2: "Niveau 2",
    exp1: "Expérience 1",
    exp2: "Expérience 2",
    attach: "Pièces jointes",
    attestation: "Attestation Insee ou Kbis",
    identity: "Identité",
    criminal: "Casier judiciaire",
    prof: "Photographie professionnelle",
    chooseFile: "Ajouter un fichier",
    noFile: "Aucun fichier",
    addMoreFile: "Ajouter un autre fichier",
    submit: "Envoyer",
    full_name: "le champ Nom est vide",
    email: "le champ E-mail est vide",
    mailFormatError: "Format d'email invalide",
    phoneError: "le champ Mobile est vide",
    cityError: "le champ Ville est vide",
    adressError: "le champ Adresse est vide",
    postal_code: "le champ Code postal est vide",
    spoken_lang: "le champ Langues parlées est vide",
    diploma1: "le champ Niveau 1 est vide",
    exp1Error: "le champ Expérience 1 est vide",
    diploma2: "le champ Niveau 2 est vide",
    exp2Error: "le champ Expérience 2 est vide",
    inseeKbis: "Ajouter un fichier pour Attestation Insee ou Kbis",
    identityDoc: "Ajouter un fichier pour l'itentité",
    criminalRecord: "Ajouter un fichier pour Casier judiciaire",
    proPhoto: "Ajouter un fichier pour Photographe professionnelle",
    error: "Erreur lors de l'envoi de la demande.",
    sending: "Envoi en cours",
    success: "Votre demande a été envoyé avec succès.",
  },
};

const GetText = () => {
  const { lang } = useContext(LangContext);
  if (!lang) {
    return texts.fr;
  } else {
    return texts[lang];
  }
};

export default GetText;
